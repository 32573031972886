
const axios = require('axios');
const { baseAPI } = require('../config/config.json');
const { getToken } = require('./storage_service');
const buildHeader = () => {
    return ({
        'Authorization': `Bearer ` + getToken(),
    })
}

const upload = async (beforeUpload, files, afterUpload) => {
    if (beforeUpload) beforeUpload();
    var formData = new FormData();
    formData.append("files", files[0]);
    const headers = buildHeader();
    headers['Content-Type'] = 'multipart/form-data'
    const res = await axios.post(`${baseAPI}/api/upload`, formData, { headers });
    if (afterUpload) afterUpload(res.data);
}

const getUserInfo = async () => {
    const endpoint = `${baseAPI}/api/users/me?populate=*`;
    return axios.get(endpoint, {headers: buildHeader()});
}

module.exports = {
    upload,
    getUserInfo
}