import { navigate } from "gatsby";
import { Box, Spinner, Text } from "grommet";
import React from "react";

import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StandardText from "../components/elements/standard_text";
import NavigationFrame from "../components/nav_frame";
import { getUserInfo } from "../service/common_service";
import storageService from "../service/storage_service";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logined: undefined,
    };
  }

  componentDidMount = () => {
    const token = storageService.getToken();
    if (!token) {
      navigate("/login");
      return;
    }
    getUserInfo()
      .then((res) => {
        storageService.saveUser(res.data);
      })
      .catch((_) => {
        storageService.clearAuth();
        navigate("/login");
      })
      .then((_) => this.setState({ logined: true }));
  };

  render() {
    return this.state === undefined ? (
      <Box align="center" justify="center" direction="row" pad={"small"}>
        <Spinner size="large" />
        <Text size="large">MEOR Platform</Text>
      </Box>
    ) : (
      <BlockUi tag="div" blocking={this.state.loading}>
        <NavigationFrame>
          <Box pad={"large"} justify="center" align="center" width={"full"}>
            <StandardText
              color={"brand"}
              bold
              size="xlarge"
              label={"Welcome to MEOR Platform"}
            />
          </Box>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </NavigationFrame>
      </BlockUi>
    );
  }
}

export default Home;
